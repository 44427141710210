// castle html content category
export const CASTLE_CONTENT_CATEGORY = {
  selfie: "castle-selfie",
  dessert: "castle-dessert",
  serviceQna: "castle-service-qna",
  preservationQna: "castle-preservation-qna",
  commonDesc: "common-desc",
  conceptBooth: "castle-conceptbooth",
}

export const CONCEPT_BOOT_QNA = "castle-concept-qna"

export const AllTag = {
  id: "all",
  title: "전체",
}

export const Tags = [
  {
    id: "lovely",
    title: "러블리",
  },
  {
    id: "dreamy",
    title: "몽환",
  },
  {
    id: "cutie",
    title: "큐티",
  },
  {
    id: "fantastic",
    title: "판타지",
  },
  {
    id: "kitsch",
    title: "키치",
  },
  {
    id: "wedding",
    title: "웨딩",
  },
]

export const Holidays = ["2025-01-29", "2025-03-02", "2025-03-03", "2025-03-04", "2025-03-05", "2025-03-06", "2025-03-07"]

export const OperationTimeList = [
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
]
export const SHOOTING_TIME = 45

export const PayOrderTypes = {
  OnlieReservation: "온라인예약",
  OnlineConceptBuy: "온라인키오스크컨셉구매",
  OnliePhotoTouch: "온라인추가보정신청",
  OnlineAddShot: "온라인키오스크추가촬영",
  OnlinePrint: "온라인인쇄",
}

export const AppVersion = 2504020

export const isMenuPage = (path) => {
  var ret = false
  switch (path) {
    case "/":
    case "/location":
    case "/reservation/buy":
    case "/reservation/package":
    case "/castle":
    case "/makeup":
    case "/mypage/home":
      ret = true
      break
    default:
      if (path.startsWith("/eventPage")) {
        ret = true
      }
      break
  }
  return ret
}

export const DEFAULT_PACKAGE_CODE = "default-package"
